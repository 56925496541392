import(/* webpackMode: "eager", webpackExports: ["platformBlocksRegistry"] */ "/runner/_work/unifyapps/unifyapps/apps/platform/src/components/registry/platform.ts");
;
import(/* webpackMode: "eager" */ "/runner/_work/unifyapps/unifyapps/node_modules/@mui/joy/Stack/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/runner/_work/unifyapps/unifyapps/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/runner/_work/unifyapps/unifyapps/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/runner/_work/unifyapps/unifyapps/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/runner/_work/unifyapps/unifyapps/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/runner/_work/unifyapps/unifyapps/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/runner/_work/unifyapps/unifyapps/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/runner/_work/unifyapps/unifyapps/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/runner/_work/unifyapps/unifyapps/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/runner/_work/unifyapps/unifyapps/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/runner/_work/unifyapps/unifyapps/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/runner/_work/unifyapps/unifyapps/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/runner/_work/unifyapps/unifyapps/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/runner/_work/unifyapps/unifyapps/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["GovernancePage","ThemedGovernancePage"] */ "/runner/_work/unifyapps/unifyapps/packages/carbon/src/no-code/components/GovernancePages/GovernancePage.tsx");
