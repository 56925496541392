'use client';

import dynamic from 'next/dynamic';
import BlockRenderer from '@unifyapps/carbon/no-code/components/BlockRenderer';
import BlocksRegistry from '@unifyapps/carbon/no-code/components/RegistryProvider/BlocksRegistry';
import { StackBlockStateDefinition } from '@unifyapps/blocks/Stack';
import { TypographyBlockStateDefinition } from '@unifyapps/blocks/Typography';
import { CardBlockStateDefinition } from '@unifyapps/blocks/Card';
import { DividerBlockStateDefinition } from '@unifyapps/blocks/Divider';
import SSOButton from '@unifyapps/blocks/SSOButton';
import { ButtonBlockStateDefinition } from '@unifyapps/blocks/Button';
import { AlertBlockStateDefinition } from '@unifyapps/blocks/Alert';
import { ImageBlockStateDefinition } from '@unifyapps/blocks/Image';
import { ContainerBlockStateDefinition } from '@unifyapps/blocks/Container';
import { LoaderBlockStateDefinition } from '@unifyapps/blocks/Loader';
import { LoginLayout } from '@unifyapps/carbon/no-code/components/LoginRenderer/components/LoginLayout';
import { TabsBlockStateDefinition } from '@unifyapps/blocks/Tabs';
import { TableBlockStateDefinition } from '@unifyapps/blocks/Table/DataTable';
import { KeyValueBlockStateDefinition } from '@unifyapps/blocks/KeyValue';
import { IconButtonBlockStateDefinition } from '@unifyapps/blocks/IconButton';
import { LinkBlockStateDefinition } from '@unifyapps/blocks/Link';
import { ProgressBlockStateDefinition } from '@unifyapps/blocks/Progress';
import { RepeatableBlockStateDefinition } from '@unifyapps/blocks/Repeatable';
import { IconBlockStateDefinition } from '@unifyapps/blocks/Icon';
import { StatCardBlockStateDefinition } from '@unifyapps/blocks/StatCard';
import { MultiStatsCardBlockStateDefinition } from '@unifyapps/blocks/MultiStatsCard';
import { Chat } from '@unifyapps/blocks/Chat';
import { ChartBlockStateDefinition } from '@unifyapps/blocks/Chart';
import { DrawerBlockStateDefinition } from '@unifyapps/blocks/Drawer';
import { ModalBlockStateDefinition } from '@unifyapps/blocks/Modal';
import { TagBlockStateDefinition } from '@unifyapps/blocks/Tag';
import { ModuleBlockStateDefinition } from '@unifyapps/blocks/Module';
import { TimelineBlockStateDefinition } from '@unifyapps/blocks/Timeline';
import { TextInputBlockStateDefinition } from '@unifyapps/blocks/TextInput';
import { CategoryFilterBlockStateDefinition } from '@unifyapps/blocks/CategoryFilter';
import { CarouselBlockFeatureStateDefinition } from '@unifyapps/blocks/Carousel';
import { FormBlockStateDefinition } from '@unifyapps/blocks/Form';
import { StepperBlockStateDefinition } from '@unifyapps/blocks/Stepper';
import { SteppedContainerBlockStateDefinition } from '@unifyapps/blocks/SteppedContainer';
import { ButtonGroupBlockStateDefinition } from '@unifyapps/blocks/ButtonGroup';
import { MenuBlockStateDefinition } from '@unifyapps/blocks/Menu';
import { TimerBlockStateDefinition } from '@unifyapps/blocks/Timer';
import { CollaborationBlockStateDefinition } from '@unifyapps/blocks/Collaboration';
import { CopilotBlockStateDefinition } from '@unifyapps/blocks/Copilot';
import { MessageInputBlockStateDefinition } from '@unifyapps/blocks/MessageInput';
import { MediaBlockStateDefinition } from '@unifyapps/blocks/Media';
import { CalendarBlockStateDefinition } from '@unifyapps/blocks/Calendar';
import { AudioPlayerBlockStateDefinition } from '@unifyapps/blocks/AudioPlayer';
import { PDFViewerBlockStateDefinition } from '@unifyapps/blocks/PDFViewer';
import CitationBlockStateDefinition from '@unifyapps/blocks/Chat/components/Citation/CitationBlockStateDefinition';
import { NavigationMenuBlockStateDefinition } from '@unifyapps/blocks/NavigationMenu';
import { NavigationContainerBlockStateDefinition } from '@unifyapps/blocks/NavigationContainer';

const Form = dynamic(
  () =>
    import(
      /* webpackChunkName: "Form" */
      '@unifyapps/blocks/DeprecatedForm'
    ),
);

const UAEntityTable = dynamic(
  () =>
    import(
      /* webpackChunkName: "UAEntityTable" */
      '@unifyapps/blocks/Table/UAEntityTable'
    ),
);

const blocks = {
  DeprecatedForm: Form,
  LoginLayout,
  SSOButton,
  UAEntityTable,
  Chat,
};

export const platformBlocksRegistry = new BlocksRegistry({
  name: 'platform',
  blockRenderer: BlockRenderer,
  blocks,
  blockStateDefinitions: [
    new AlertBlockStateDefinition(),
    new ButtonBlockStateDefinition(),
    new CardBlockStateDefinition(),
    new ChartBlockStateDefinition(),
    new ContainerBlockStateDefinition(),
    new IconButtonBlockStateDefinition(),
    new DividerBlockStateDefinition(),
    new KeyValueBlockStateDefinition(),
    new LinkBlockStateDefinition(),
    new LoaderBlockStateDefinition(),
    new ProgressBlockStateDefinition(),
    new StackBlockStateDefinition(),
    new TabsBlockStateDefinition(),
    new CarouselBlockFeatureStateDefinition(),
    new ModalBlockStateDefinition(),
    new TypographyBlockStateDefinition(),
    new RepeatableBlockStateDefinition(),
    new IconBlockStateDefinition(),
    new ImageBlockStateDefinition(),
    new StatCardBlockStateDefinition(),
    new MultiStatsCardBlockStateDefinition(),
    new TableBlockStateDefinition(),
    new DrawerBlockStateDefinition(),
    new TagBlockStateDefinition(),
    new ModuleBlockStateDefinition(),
    new TimelineBlockStateDefinition(),
    new TextInputBlockStateDefinition(),
    new CategoryFilterBlockStateDefinition(),
    new StepperBlockStateDefinition(),
    new SteppedContainerBlockStateDefinition(),
    new ButtonGroupBlockStateDefinition(),
    new FormBlockStateDefinition(),
    new MenuBlockStateDefinition(),
    new TimerBlockStateDefinition(),
    new CollaborationBlockStateDefinition(),
    new CopilotBlockStateDefinition(),
    new MessageInputBlockStateDefinition(),
    new MediaBlockStateDefinition(),
    new CalendarBlockStateDefinition(),
    new AudioPlayerBlockStateDefinition(),
    new PDFViewerBlockStateDefinition(),
    new CitationBlockStateDefinition(),
    new NavigationMenuBlockStateDefinition(),
    new NavigationContainerBlockStateDefinition(),
  ],
});
